const androidVersion = parseFloat(getAndroidVersion())

function getAndroidVersion (ua) {
  ua = (ua || navigator.userAgent).toLowerCase()
  var match = ua.match(/android\s([0-9\.]*)/i); // eslint-disable-line
  return match ? match[1] : undefined
}

window.onload = () => {
  if (androidVersion <= 7) {
    const warning = document.querySelector('.android-warning')
    const warningHeight = warning.offsetHeight

    warning.style.display = 'block'
    document.body.style.paddingBottom = warningHeight + 'px'
  }
}
